import React from 'react'

const HeaderGeneric = (props) => (
    <header id="header">
        <h1>PPE4ALL</h1>
        <p>Our inaugural initiative to aid in the PPE shortage crisis during the COVID-19 pandemic</p>
    </header>
)

export default HeaderGeneric
